import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import AdsTable from '../account/adstable.js'
import AdsShortList from '../account/adsShortList'
import axios from 'axios'
import {
    Button,
    Box,
    Stack,
    Text
} from '@chakra-ui/react'

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import { FormattedMessage } from 'react-intl'
const siteConfig = require('../../../config');

const YourAds = (props) => {
    const [ads, updateAds] = useState(false)
    const [deletingAdId, setDeletingAdId] = useState(false);

    useEffect(
        () => getUserAds(),
        []);

    const getUserAds = () => {
        if (props.userID) {
            // console.log('getUserAds')
            axios.get(`${process.env.GATSBY_API_URL}/annonce/user/${props.userID}`)
                .then(response => updateAds(response.data))
        } else {
            console.log('else getUserAds')
        }
    }

    const handleDelete = (adId, isWbUsefull) => {

        // console.log('handleDelete', adId, isWbUsefull)

        if (isWbUsefull) {
            axios.delete(
                `${process.env.GATSBY_API_URL}/annonce/${adId}`,
                { data: { usefullToSell: isWbUsefull } }
            ).then(() => {
                navigate(`${siteConfig.links.donation[process.env.GATSBY_LANG]}?ad=${adId}`)
            })
        } else {
            //
            axios.delete(
                `${process.env.GATSBY_API_URL}/annonce/${adId}`,
                { data: { usefullToSell: isWbUsefull } }
            ).then(() => {
                navigate(`../`)
            })
        }

    };

    return (
        <Box>

            {ads.length > 10000 ?
                <AdsTable
                    data={ads}
                    reloadAds={getUserAds}
                    startEditingAd={null}
                    setDeletingAdId={(id) => {
                        console.log('setDeletingAdId', id)
                        setDeletingAdId(id)
                    }}
                />
                : ads.length > 0
                    ?
                    <AdsShortList
                        data={ads}
                        reloadAds={getUserAds}
                        startEditingAd={null}
                        setDeletingAdId={(id) => {
                            console.log('setDeletingAdId', id)
                            setDeletingAdId(id)
                        }}
                    />
                    :
                    <Text>
                        <FormattedMessage id='account.ad.no.ad.online.notice' />
                    </Text>
            }

            <Modal
                isOpen={deletingAdId}
                onClose={() => { setDeletingAdId(false) }}
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    h={{ base: '100vh', lg: 'auto' }}
                    borderRadius={{ base: '0', lg: 'lg' }}
                >
                    <ModalHeader>
                        <FormattedMessage id='delete.wizard.title' />
                    </ModalHeader>
                    <ModalCloseButton onClick={() => { setDeletingAdId(false) }} />
                    <ModalBody>
                        <Stack spacing={4}>
                            <Text>
                                <FormattedMessage id='delete.wizard.notice.1' />&nbsp;:
                            </Text>
                            <Text
                                fontWeight='bold'
                            >
                                <FormattedMessage id='delete.wizard.notice.2' />&nbsp;?
                            </Text>

                            <Stack my='1rem' isInline spacing=".5rem">
                                <Button onClick={() => { handleDelete(deletingAdId, true) }} colorScheme="blue">
                                    <FormattedMessage id='main.yes' /> 😃
                                </Button>
                                <Button onClick={() => { handleDelete(deletingAdId, false) }}>
                                    <FormattedMessage id='main.no' /> 😓
                                </Button>
                            </Stack>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>

        </Box>
    )
}

export default YourAds