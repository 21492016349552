import React, { useState } from 'react'
import axios from 'axios'
import IntPhoneInput from '../ad/IntlPhoneNumber'
import { Box, Button, FormControl, FormLabel, Input, Heading, Stack, SimpleGrid, Text } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'

const UpdateContactForm = (props) => {
    const
        [userName, setUserName] = useState(props.user.name),
        [userPhone, setUserPhone] = useState(props.user.phone),
        [isUpdated, setIsUpdate] = useState(false),
        [isRecording, setIsRecording] = useState(false);

    const publishUpdates = () => {
        axios.patch(`${process.env.GATSBY_API_URL}/user/${props.user._id}`,
            { "name": userName, "phone": userPhone }
        )
            .then(function (response) {
                console.log(response);
                setIsUpdate(false)
                this.props.onUpdate(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <Stack spacing="1rem">
            <Heading mb={2} fontSize={22}>
                <FormattedMessage id="account.nav.setttings.your.contact" />&nbsp;:
            </Heading>
            <SimpleGrid columns={{ base: 1 }} gap='1rem'>
                <FormControl>
                    <FormLabel htmlFor="email"><FormattedMessage id="form.step9.name.and.contact" />&nbsp;:</FormLabel>
                    <Input
                        value={userName}
                        onChange={(e) => {
                            setIsUpdate(true)
                            setUserName(e.target.value)
                        }}
                        type="text"
                    />
                </FormControl>
                <FormControl>
                    {/* <FormLabel htmlFor="email">Votre nom :</FormLabel> */}
                    <IntPhoneInput
                        value={userPhone}
                        updatePhoneNumber={(value) => {
                            setIsUpdate(true)
                            console.log('value', value)
                            setUserPhone(value)
                        }}
                    />
                </FormControl>
                {isUpdated ?
                    <Box>
                        <Button
                            colorScheme="green"
                            onClick={() => { publishUpdates() }}
                            isLoading={isRecording}
                            disabled={isRecording}
                        >
                            Valider ces modifications
                        </Button>
                    </Box>
                    : null}
            </SimpleGrid>
            <Text>
                <FormattedMessage id="account.nav.setttings.email.update.notice" />
                {/* Pour mettre à jour votre adresse email, faites la demande à vhf@wanaboat.fr */}
            </Text>
        </Stack>
    )
}

export default UpdateContactForm