import React, { Component } from 'react';
import axios from "axios";

import TimeAgo from '@jshimko/react-time-ago';
import frenchStrings from '@jshimko/react-time-ago/lib/language-strings/fr-short';
import buildFormatter from '@jshimko/react-time-ago/lib/formatters/buildFormatter';
import AdCardLandscapeSmaller from '../ad/cardLandscapeSmaller'
import DisplayNicePhoneNumber from '../PhoneNumber.js'
import {
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Link,
    Textarea,
    Text,
    Spinner,
    Stack
} from "@chakra-ui/react";

import { FormattedMessage, injectIntl } from 'react-intl'

// import Form from '../Form/AdForm';

const formatter = buildFormatter(frenchStrings);

class Discussion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            discussionId: this.props.id,
            isLoaded: false,
            ad: null,
            sender: null,
            receiver: null,
            buttonGetContactIsVisible: true,
            isSendingMessage: false,
            messages: [null]
        };
    }

    getDiscussionData = () => {
        fetch(
            process.env.GATSBY_API_URL + "discussion/" + this.props.id + '/user/' + this.props.userID,
            { method: "GET" }
        )
            .then(res => res.json())
            .then(
                result => {
                    this.setState({
                        messages: result.messages,
                        ad: result.ad,
                        sender: result.sender,
                        receiver: result.receiver,
                        isContactsExchanged: result.contacts_exchanged,
                        isLoaded: true
                    });
                },
                error => {
                    console.log(error);
                }
            );
    }

    async componentDidMount() {
        this.getDiscussionData();
        this.intervalID = setInterval(this.getDiscussionData.bind(this), 5000);
    }
    componentWillUnmount() {
        clearInterval(this.intervalID);
    }
    render() {
        const { messages, isLoaded, isSendingMessage, discussionId, sender, receiver, isContactsExchanged } = this.state
        const { userID, ad } = this.props

        const handlePostMessage = (e) => {
            e.preventDefault();

            this.setState({ isSendingMessage: true })
            var _this = this
            let form = e.target;
            // console.log('submit form', form);

            let data = {
                user_id: userID,
                discussion_id: discussionId,
                content: form.querySelector("#content").value,
                contacts_exchanged: false
            };
            axios({
                method: "POST",
                url: process.env.GATSBY_API_URL + "discussion/message",
                data: data
            })
                .then(function (response) {
                    // console.log(response);
                    form.reset();
                    setTimeout(() => {
                        _this.setState({ isSendingMessage: false })
                    }, 2000)
                    // console.log(e.target);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        const handlePostPhoneMessage = (e) => {
            this.setState({ isContactsExchanged: true })
            let data = {
                user_id: userID,
                discussion_id: discussionId,
                content: `Merci pour votre message, je vais vous recontacter très prochainement, par mail ou par téléphone.\n\n Voici les coordonnées transmises par Wanaboat :\n - tél : ${this.state.sender.phone} \n - email : ${this.state.sender.email} \n\n Sont-elles bien correctes ? \n\n À bientôt, ${this.state.receiver.name}`,
                contacts_exchanged: true
            };
            axios({
                method: "POST",
                url: process.env.GATSBY_API_URL + "discussion/message",
                data: data
            })
                .then(function (response) {
                    // console.log(response);
                    // form.reset();
                    // console.log(e.target);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        // console.log( 'ad', ad )
        // console.log( 'intl', this.props.intl.locale )

        return (
            <>
                {(isLoaded && userID) ?
                    <Box>
                        <Box mb={4}>
                            <Button onClick={this.props.action}><FormattedMessage id="nav.back" /></Button>
                        </Box>
                        {messages.map(message =>
                            <Box key={message.id}>
                                <Flex
                                    justify={(parseInt(message.user.id) === parseInt(userID)) ? "flex-end" : "flex-start"}
                                >
                                    <Box
                                        p={4}
                                        w="80%"
                                        borderRadius={4}
                                        backgroundColor={(parseInt(message.user.id) === parseInt(userID)) ? "blue.brand" : "gray.200"}
                                        color={(parseInt(message.user.id) === parseInt(userID)) ? "white" : "gray.800"}
                                    >
                                        <Text fontSize="xs" mb=".25rem">
                                            {message.user.name}{' '}
                                            <TimeAgo date={message.created_at} formatter={formatter} />
                                        </Text>
                                        <div dangerouslySetInnerHTML={{ __html: message.content.replace(/\n/g, "<br />") }} />
                                    </Box>
                                </Flex>
                                {
                                    (
                                        message.user.id === userID
                                        && message.created_at !== message.updated_at
                                        // Feature launch
                                        && new Date(message.updated_at).getTime() > new Date("2020-02-02 16:30:00").getTime()
                                    ) ?
                                        <Text mt={1} fontSize="xs" textAlign="right">
                                            ✓ <FormattedMessage id="account.message.is.read" />
                                            <TimeAgo date={message.updated_at} formatter={formatter} />
                                        </Text>
                                        :
                                        <Box>&nbsp;</Box>
                                }

                            </Box>
                        )}
                        {isContactsExchanged ?
                            <Flex
                                mt="20px"
                                justifyContent="space-between"
                                backgroundColor="gray.50"
                                borderRadius="lg"
                                border="dashed 1px"
                                borderColor="gray.100"
                                p="1rem"
                                my="1rem"
                                fontSize={{ base: "12px", lg: "14px" }}
                                wordBreak="break-all"
                            >
                                {parseInt(receiver.id) === parseInt(userID) ?
                                    <>
                                        <Box>
                                            <Text fontWeight="bold">{sender.name}</Text>
                                            <Text>Email: <Link href={`mailto:${sender.email}`}>{sender.email}</Link></Text>
                                            <Text><FormattedMessage id="account.message.phone" />: <Link href={`tel:${sender.phone}`}>
                                                {/* {sender.phone} */}
                                                <DisplayNicePhoneNumber>{sender.phone}</DisplayNicePhoneNumber>
                                            </Link></Text>
                                        </Box>
                                        <Divider borderColor="gray.200" orientation="vertical" />
                                        <Box textAlign="right">
                                            <Text fontWeight="bold">{receiver.name}</Text>
                                            <Text>Email: {receiver.email}</Text>
                                            {receiver.phone !== '' ?
                                                <Text>Tél: {receiver.phone}</Text>
                                                : null}
                                        </Box>
                                    </>
                                    :
                                    <>

                                        <Box>
                                            <Text fontWeight="bold">{receiver.name}</Text>
                                            <Text>Email: {receiver.email}</Text>
                                            {receiver.phone !== '' ?
                                                <Text>
                                                    <FormattedMessage id="account.message.phone" />:
                                                    <Link href={`tel:${receiver.phone}`}>
                                                        <DisplayNicePhoneNumber>{receiver.phone}</DisplayNicePhoneNumber>
                                                    </Link>
                                                </Text>
                                                : null}
                                        </Box>
                                        <Divider borderColor="gray.200" orientation="vertical" />
                                        <Box textAlign="right">
                                            <Text fontWeight="bold">{sender.name}</Text>
                                            <Text>Email: <Link to={`mailto:${sender.email}`}>{sender.email}</Link></Text>
                                            {sender.phone !== '' ?
                                                <Text>
                                                    <FormattedMessage id="account.message.phone" />:
                                                    <Link href={`tel:${sender.phone}`}>
                                                        <DisplayNicePhoneNumber>{sender.phone}</DisplayNicePhoneNumber>
                                                    </Link>
                                                </Text>
                                                : null}

                                        </Box>
                                    </>
                                }
                            </Flex>
                            : null}

                        {parseInt(receiver.id) === parseInt(userID) && !isContactsExchanged ?
                            <Flex
                                justify="center"
                                my={".5rem"}
                            >
                                <Box
                                    backgroundColor="gray.50"
                                    border="dashed 1px"
                                    borderColor="gray.100"
                                    p="1rem"
                                    w={{ lg: "80%" }}
                                    textAlign="center"
                                    borderRadius="lg"
                                >
                                    <Text mb="1rem">
                                        <FormattedMessage id="account.do.you.want.to.share.contact" />
                                    </Text>
                                    <Button
                                        size="sm"
                                        colorScheme="blue"
                                        maxW="100px"
                                        px="2rem"
                                        onClick={handlePostPhoneMessage}
                                    ><FormattedMessage id="main.yes" /></Button>
                                </Box>
                            </Flex>
                            : null}
                        <form as="form" onSubmit={handlePostMessage}>
                            {/* <input name="user_id" id="user_id" type="hidden" value={userID} />
                            <input name="discussion_id" id="discussion_id" type="hidden" value={this.props.id} /> */}
                            <FormControl>
                                <FormLabel htmlFor="content">Message</FormLabel>
                                <Textarea
                                    name="content"
                                    borderRadius={messages.length === 1 ? "3px 3px 0 0" : "3px"}
                                    borderBottom={messages.length === 1 ? "none" : "solid 1px gray.100"}
                                    id="content"
                                    placeholder="Bassine rouge, tricot vert.."></Textarea>
                                {
                                    parseInt(messages.length) === 1
                                        && parseInt(ad.user_id) === parseInt(userID) ?
                                        <Flex backgroundColor="gray.500" borderRadius="0 0 3px 3px" p="0.45rem" color="white" fontSize="12px">
                                            <FormattedMessage id="account.message.velocity.notice" />
                                        </Flex>
                                        :
                                        null
                                }
                            </FormControl>
                            <Flex mt={2} justify="flex-end">
                                <Button isLoading={isSendingMessage} type="submit" colorScheme="green"><FormattedMessage id="main.send" /></Button>
                            </Flex>
                        </form>
                        <Stack mt="20px" spacing='.5rem'>
                            <Text>
                                <FormattedMessage id="account.ad.being.discussed" />:
                            </Text>
                            <AdCardLandscapeSmaller
                                name={ad[`title_${this.props.intl.locale}`]}
                                images={ad.pictures}
                                key={ad.id}
                                region={ad.region}
                                price={ad.price}
                                to={ad[`slug_${this.props.intl.locale}`]}
                            // currency={ currency }
                            />
                        </Stack>
                    </Box>
                    : <Box><Spinner mr="1rem" size="xs" />Chargement en cours</Box>}
            </>
        )
    }
}

export default injectIntl(Discussion);