import React, { useState, useRef } from 'react'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Textarea,
    Text
} from '@chakra-ui/react'

const MessageForm = ({ discussion, userID, refreshDiscussion }) => {
    const [isSendingMessage, setIsSendingMessage] = useState(false)
    const messageField = useRef()
    const handlePostMessage = (e) => {
        e.preventDefault()
        setIsSendingMessage(true)
        var _this = this
        let form = e.target;
        // console.log('submit form', form);

        let data = {
            user_id: userID,
            discussion_id: discussion._id,
            content: form.querySelector("#content").value,
            contacts_exchanged: false
        };
        axios.post(
            `${process.env.GATSBY_API_URL}/message/`,
            {
                user: userID,
                discussion: discussion.data._id,
                content: messageField.current.value
            }
        ).then(function (response) {
            // console.log(response);
            form.reset();
            setTimeout(() => {
                setIsSendingMessage(false);
                refreshDiscussion();
            }, 750)
            // console.log(e.target);
        })
            .catch(function (error) {
                console.log(error);
            });


    }
    return (
        discussion && !discussion.data.isArchived ?
            <Box
                as="form"
                onSubmit={handlePostMessage}
                mt={{ base: 5, lg: 10 }}
            >
                {/* <pre>
                { JSON.stringify( discussion, null, 1 )}
            </pre> */}
                <FormControl>
                    <FormLabel htmlFor="content">Message</FormLabel>
                    <Box
                        borderRadius='3px'
                        border='solid 1px gray.100'
                    >


                        <Textarea
                            required
                            ref={messageField}
                            id="content"
                            placeholder="Bassine rouge, tricot vert.."></Textarea>
                        {
                            // discussion.messages.length === 1
                            // && 
                            discussion.data.ad.user === userID ?
                                <Flex backgroundColor="gray.500" borderRadius="0 0 3px 3px" p="0.45rem" color="white" fontSize="12px">
                                    <FormattedMessage id="account.message.velocity.notice" />
                                </Flex>
                                :
                                null
                        }
                    </Box>
                </FormControl>
                <Flex mt={2} justify="flex-end">
                    <Button isLoading={isSendingMessage} type="submit" colorScheme="green"><FormattedMessage id="main.send" /></Button>
                </Flex>
            </Box>

            :
            <Box
                fontSize='14px'
                color='gray.500'
                py={5}
                my={5}
                borderTop='solid 1px'
                borderTopColor='gray.100'
                textAlign='center'
            >
                <FormattedMessage id='account.archived.discussion.notice' />
            </Box>

    )
}

export default MessageForm