import React, { Component } from "react"
import { navigate } from 'gatsby'
import Loadable from "react-loadable"
import axios from "axios"

import {
    Box,
    Button,
    Input,
    Flex,
    FormControl,
    FormLabel,
    Stack,

    Spinner,
    Text
} from "@chakra-ui/react"

import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

import Step3Model from "./Step3_0Model"
import Step35Brand from "./Step3_5Brand"
import Step35Year from "./Step3_5Year";
import Step4Location from "./Step4Location"
import Step7Pictures from "./Step7Pictures";
import Step8Price from "./Step8Price";
import FormUpdatePictures from './AdEditFormPictures'

import Axios from "axios"
import { FormattedDate, FormattedMessage } from "react-intl"
import { BiX } from "react-icons/bi"
var markdown = require("markdown").markdown;


const contentful = require("contentful");
const client = contentful.createClient({
    space: process.env.GATSBY_CONTENTFUL_SPACE,
    environment: "master",
    accessToken: process.env.GATSBY_CONTENTFUL_TOKEN,
});


class AdEditForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ad: null,
            locale: process.env.GATSBY_LANG,
            updateIsProcessing: false,
            isLoaded: false,
            isUpdateProceed: false,
            contentEditorVisible: false
        }
        // this.handleChange = this.handleChange.bind(this)

    }

    getModelName(modelID) {
        var _this = this;
        client.getEntry(modelID).then(function (entry) {
            // console.log(entry);
            _this.setState({ modelName: entry.fields.name });
        });
    }

    extractUploadFolder(pictures) {
        if (pictures.length) {
            var folder = pictures[0].split('/')[1];
        } else {
            var folder = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        }
        this.setState({ "uploadDir": folder });

    }

    componentDidMount() {
        this.getAdData();
    }

    getAdData() {
        Axios.get(
            `${process.env.GATSBY_API_URL}/annonce/${this.props.adId}`
        ).then((ad) => this.setState({ ad: ad.data, isLoaded: true }))
    }

    render() {

        const {
            ad,
            contentEditorVisible,
            documents,
            isLoaded,
            city,
            region,
            refUnivers,
            refVersion,
            refBrand,
            modelName,
            uploadDir,
            updateIsProcessing
        } = this.state

        const Step6ContentEditor = Loadable({
            loader: () => import('./Step6ContentEditor'),
            loading() {
                return <Box><Spinner color="blue.brand" mr='.5rem' />Chargement de l'éditeur...</Box>
            }
        });

        const handleSubmit = () => {
            var _this = this;
            console.log(this.state)

            // this.setState({ updateIsProcessing: true });
            axios
                .patch(
                    `${process.env.GATSBY_API_URL}/annonce/${this.state.ad._id}`,
                    this.state.ad
                )
                .then(function () {
                    navigate('../../')
                });
        }

        const handleChange = (key, value) => {
            console.log(key, value)
            let { ad } = this.state
            ad[key] = value
            this.setState({ ad: ad }, console.log(this.state))

        }

        const typology = (ad) => {
            if (ad.isBoat) {
                return 'isBoat'
            }
            else if (ad.isSail) {
                return 'isSail'
            }
            else if (ad.isPart) {
                return 'isPart'
            }
            else if (ad.isTrolley) {
                return 'isTrolley'
            }
        }

        // return( <Text>Fonctionnalité en cale sèche, retour dans quelques jours </Text>)
        return (
            isLoaded ?
                <Stack
                    px={{ base: 0, lg: "4rem" }}
                    spacing="2.5rem"
                    shouldWrapChildren={true}
                >
                    <Box>
                        <Button
                            onClick={this.props.cancel}
                            leftIcon={<BiX />}
                        >
                            <FormattedMessage id='main.cancel' />
                        </Button>
                    </Box>

                    <Text
                        color='gray.500'
                        letterSpacing='wide'
                    >
                        <FormattedMessage id='form.update.translation.notice' />.
                    </Text>

                    {/* <Text>AdEditForm {AdID}</Text> */}

                    <Tabs size='sm'>
                        <TabList mb={4}>
                            <Tab>Français</Tab>
                            <Tab>English</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel p={0}>
                                <FormControl>
                                    <Input
                                        id="titleFr"
                                        name="titleFr"
                                        size="lg"
                                        // disabled={isValid}
                                        onChange={(e) => {
                                            handleChange(e.target.name, e.target.value)
                                        }}
                                        placeholder="Un super bateau que je vous propose"
                                        value={ad.titleFr}
                                    />
                                </FormControl>
                            </TabPanel>
                            <TabPanel p={0}>
                                <FormControl>
                                    <Input
                                        id="titleEn"
                                        name="titleEn"
                                        size="lg"
                                        // disabled={isValid}
                                        onChange={(e) => {
                                            handleChange(e.target.name, e.target.value)
                                        }}
                                        placeholder="A wonderful boat"
                                        value={ad.titleEn}
                                    />
                                </FormControl>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>





                    {/* <Step3Model
                        key={`${refUnivers}-${refVersion}`}
                        univers={ad.refUnivers}
                        refUniversCode={ad.refUnivers}
                        typology={typology(ad)}
                        modelName={modelName}
                        refVersion={ad.refVersion}
                        refModel={ad.refModel}

                        handleChange={console.log('handleChange')}
                    />

                    <Step35Brand
                        key="4zJDaRiNg9nlD69B9U6Ot"
                        univers="4zJDaRiNg9nlD69B9U6Ot"
                        typology={typology(ad)}
                        refBrand={refBrand}
                        handleChange={(value) => this.handleChange('refBrand', value)}
                    /> */}
                    <Step35Year
                        typology={typology}
                        year={ad.year}
                        handleChange={(key, value) => {
                            console.log(value)
                            handleChange('year', value)
                        }}
                    />

                    {/* <Step4Location
                        univers={refUnivers}
                        typology={typology}
                        value={`${ad.city}, ${ad.region}`}
                        isValid={true}
                    // handleChange={this.handleChange}
                    /> */}
                    {/* <Step7Pictures
                        pictures={ad.pictures}
                        adTitle={ad.titleFr}
                        typology={typology(ad)}
                        handleChange={this.handleChange}
                        isValid={ true }
                    /> */}

                    <Tabs size='sm'>
                        <TabList mb={4}>
                            <Tab>Français</Tab>
                            <Tab>English</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel
                                p={0}
                            >
                                {contentEditorVisible ?
                                    <Step6ContentEditor
                                        typology={typology(ad)}
                                        handleChange={handleChange}
                                        fieldName='descriptionFr'
                                        closeEditor={() => { this.setState({ contentEditorVisible: false }) }}
                                        originalDescription={ad.descriptionFr}
                                        uploadDir={uploadDir}
                                        documents={documents}
                                    />
                                    :
                                    <Box
                                        borderRadius="6px"
                                        cursor='text'
                                        className="wysiwyg"
                                        p="10px"
                                        border="solid 1px"
                                        borderColor="gray.100"
                                        _hover={{
                                            borderColor: 'gray.300'
                                        }}
                                        onClick={() => {
                                            this.setState({ contentEditorVisible: true });
                                        }}
                                    >
                                        <Box
                                            className="wysiwyg"
                                            dangerouslySetInnerHTML={{
                                                "__html": markdown.toHTML(ad.descriptionFr)
                                            }}
                                        />
                                    </Box>
                                }
                            </TabPanel>

                            <TabPanel p={0}>

                                {contentEditorVisible ?
                                    <Step6ContentEditor
                                        typology={typology(ad)}
                                        handleChange={handleChange}
                                        fieldName='descriptionEn'
                                        closeEditor={() => { this.setState({ contentEditorVisible: false }) }}
                                        originalDescription={ad.descriptionEn}
                                        uploadDir={uploadDir}
                                        documents={documents}
                                    />
                                    :
                                    <Box
                                        borderRadius="6px"
                                        cursor='text'
                                        className="wysiwyg"
                                        p="10px"
                                        border="solid 1px"
                                        borderColor="gray.100"
                                        _hover={{
                                            borderColor: 'gray.300'
                                        }}
                                        onClick={() => {
                                            this.setState({ contentEditorVisible: true });
                                        }}
                                    >
                                        <Box
                                            className="wysiwyg"
                                            dangerouslySetInnerHTML={{
                                                "__html": markdown.toHTML(ad.descriptionEn)
                                            }}
                                        />
                                    </Box>
                                }
                            </TabPanel>
                        </TabPanels>
                    </Tabs>

                    <FormUpdatePictures
                        pictures={ad.pictures}
                        handleChange={(value) => {
                            console.log(value)
                            handleChange('pictures', value);
                        }}
                        key={ad.pictures}
                    />

                    <Step8Price
                        price={ad.price}
                        typology={typology}
                        currency={ad.currency}
                        handleChange={handleChange}
                    />

                    <Box>
                        <Button
                            onClick={handleSubmit}
                            size="lg"
                            colorScheme="green"
                            isLoading={updateIsProcessing}
                            loadingText="Enregistrement..."
                        >
                            <FormattedMessage id='main.save' />
                        </Button>
                    </Box>
                </Stack>

                : <Flex alignItems='center'><Spinner size='sm' color="blue.brand" mr='.5rem' />Chargement en cours</Flex>

        )
    }

}

export default AdEditForm