import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, Stack, SimpleGrid, Text } from '@chakra-ui/react'
import { FormattedMessage as FM } from 'react-intl'
import UpateUserForm from './UpateUserForm'
import authHeader from '../../../services/auth-header'
import AuthService from '../../../services/auth.service'
import axios from 'axios'

const Settings = (props) => {
    const { userID } = props
    const [user, setUser] = useState(props.user.locale)
    // const [ userName, setUserName ] = useState( props.user.name )
    // const [ userPhone, setUserPhone ] = useState( props.user.phone.substring(4,10) )
    const [userNewsletter, setUserNewsletter] = useState(props.user.accept_newsletter)
    const [windowLoaded, setWindowLoaded] = useState(false);

    const logOut = () => {
        if (windowLoaded) {
            localStorage.removeItem('WbUserToken');
            localStorage.removeItem('UserID');
            localStorage.removeItem('WbUser');
            localStorage.removeItem('WbAuth');
            localStorage.removeItem('WbFavoriteAds');
            AuthService.logout();
            navigate('/');
        }

    }

    useEffect(() => {
        if (!windowLoaded) {
            if (typeof window !== 'undefined' && window) {
                setWindowLoaded(true)
            }
        }
        getData()
    }, [windowLoaded]);

    const getData = () => {
        if (userID) {
            axios.get(
                `${process.env.GATSBY_API_URL}/user/full/${userID}`,
                { headers: authHeader() }
            )
                .then((response) => {
                    setUser(response.data)
                })
        }

    }


    const updateNewsletter = (signup) => {
        axios.patch(`${process.env.GATSBY_API_URL}/user/${props.userID}/`, {
            acceptNewsletter: signup
        })
            .then(function (response) {
                props.updateUserData(response.data)
                setUserNewsletter(signup)
                getData()

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const updateLanguage = (lang) => {
        console.log('update lang')
        axios.patch(`${process.env.GATSBY_API_URL}/user/${props.userID}`, {
            locale: lang
        })
            .then(function (response) {
                getData()

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        user ?
            <Stack spacing="3rem" shouldWrapChildren={true}>
                <Stack spacing="1rem">
                    <Heading mb={2} fontSize={22}>
                        <FM id="account.settings.log.out" />
                    </Heading>
                    <Text>
                        <FM id="account.settings.log.out.notice" />
                    </Text>
                    <Box>
                        <Button
                            w='auto'
                            onClick={logOut}
                            colorScheme="orange"
                        ><FM id="account.settings.log.out" /></Button>
                    </Box>
                </Stack>
                <UpateUserForm user={user} onUpdate={props.setUserData} />

                <Stack spacing="1rem" id="newsletter">
                    <Heading mb={2} fontSize={22}>Newsletter</Heading>
                    <Text>
                        <FM id="account.settings.newsletter.notice" />
                    </Text>
                    <Flex justify="flex-start" align="center">
                        <Stack isInline justify="flex-start">
                            <Button onClick={() => { updateNewsletter(true) }} colorScheme={user.acceptNewsletter ? 'blue' : 'gray'}><FM id="main.yes" /></Button>
                            <Button onClick={() => { updateNewsletter(false) }} colorScheme={!user.acceptNewsletter ? 'blue' : 'gray'}><FM id="main.no" /></Button>
                        </Stack>
                    </Flex>
                </Stack>
                <Stack spacing="1rem">
                    <Heading mb={2} fontSize={22}><FM id="account.settings.preferred.language" /></Heading>
                    <Stack isInline justify="flex-start">
                        <Button onClick={() => { updateLanguage('en') }} colorScheme={user.locale === 'en' ? 'blue' : 'gray'}>English</Button>
                        <Button onClick={() => { updateLanguage('fr') }} colorScheme={user.locale === 'fr' ? 'blue' : 'gray'}>Français</Button>
                    </Stack>
                </Stack>
                <Stack spacing="1rem">
                    <Heading mb={2} fontSize={22}><FM id="account.settings.final.deletion" /></Heading>
                    <Text>
                        <FM id="account.settings.final.deletion.notice" />.
                    </Text>
                </Stack>
            </Stack>
            : 'Loading'
    )
}

export default Settings