import React, { Component } from 'react';
import axios from 'axios';

import {
    Box,
    Text,
    Stack,
    Tag,
    TagLabel,
    TagCloseButton,
    Flex,
    Button
} from "@chakra-ui/react";
import { FormattedMessage } from 'react-intl';
import { BiX } from 'react-icons/bi';
import { UserContext } from '../userContext';
import Debug from '../../utils/Debug';
const contentful = require("contentful");
const client = contentful.createClient({
    space: process.env.GATSBY_CONTENTFUL_SPACE,
    environment: 'master',
    accessToken: process.env.GATSBY_CONTENTFUL_TOKEN,
});
export const AlertsList = ({ userID }) => {

    const [alerts, setAlerts] = React.useState()
    const [models, setModels] = React.useState()

    const { user } = React.useContext(UserContext);



    React.useEffect(
        () => {

            getUserAlerts();

        }, []
    )

    React.useEffect(
        () => {

            if (alerts) {

                client.getEntries(
                    {
                        content_type: 'model',
                        "sys.id[in]": alerts.map(item => item.modelId).join(',')
                    }
                ).then(
                    // res => _this.setState({ [element.modelId]: entry.fields.name })
                    res => setModels(res.items)

                )

            }


        }, [alerts]
    )

    const getUserAlerts = () => {
        axios.get(
            `${process.env.GATSBY_API_URL}/alert/user/${userID ? userID : "6058ed878ae9b4604d080d00"}`
        ).then(
            (response) => setAlerts(response.data))
    }





    const handleDelete = (alertId) => {
        axios.delete(`${process.env.GATSBY_API_URL}/alert/${alertId}`)
            .then(function (response) {
                if (response) {
                    getUserAlerts();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    return (
        <>
            {(alerts && models) ?
                <Stack spacing={4}>
                    {/* <Debug data={alerts ? alerts : ''} /> */}
                    <Text><FormattedMessage id="account.alerts.title" />:</Text>
                    <Box>
                        <Stack
                            spacing={4}
                        >
                            {alerts.map(item => (
                                <Flex
                                    key={item._id}
                                    rounded="sm"
                                    variant="solid"
                                    // colorScheme="blue"
                                    p={2}
                                    justify='space-between'
                                    boxShadow={'md'}
                                    alignItems='center'
                                >
                                    {models.filter(model => model.sys.id === item.modelId)[0].fields.name}
                                    <Button
                                        leftIcon={<BiX />}
                                        size='sm'
                                        onClick={() => handleDelete(item._id)}
                                    >
                                        <FormattedMessage id='main.delete' />
                                    </Button>
                                </Flex>
                            ))}
                        </Stack>
                    </Box>
                    <Text><FormattedMessage id="account.alerts.notice" /></Text>
                </Stack>
                : <Text><FormattedMessage id="account.alerts.no.alert.notice" /></Text>}
        </>
    )
}


export default AlertsList