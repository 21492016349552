import React, { Component } from "react";
import axios from "axios";
import {
  Button,
  Text,
  Input,
  FormControl,
  FormLabel,
  Divider,
  FormHelperText,
  FormErrorMessage,
} from "@chakra-ui/react";
import { FormattedMessage, injectIntl } from "react-intl";

class Step4Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: null,
      geoCoordinates: null,
      fieldValue: this.props.value ? `${this.props.value}` : '',
      isValid: this.props.isValid ? this.props.isValid : false
    };
  }

  getGeoCode() {
    const { fieldValue } = this.state;
    var _this = this;
    if (fieldValue && fieldValue.length >= 4) {
      axios.get('https://geocoder.ls.hereapi.com/6.2/geocode.json', {
        'params': {
          'apiKey': "Ah5FI29iYvRbn9GaukArm8bR67OdLD_pve4akvJUALc",
          'searchtext': `${fieldValue}` + " ",
          'maxresults': 1,
        }
      }).then(function (response) {
        const result = (response.data.Response.View) ? response.data.Response.View[0] : null;
        const location = (result && result.Result) ? result.Result[0].Location.Address : null;
        const geoCoordinates = (result && result.Result) ? result.Result[0].Location.DisplayPosition : null;

        if (result) {
          if (result.Result[0].Relevance === 1) {
            // console.log( result.Result[0].Relevance, location );
            _this.setState({ "location": location })
            _this.setState({ "geoCoordinates": geoCoordinates }, () => { console.log(_this.state) })
          }
        }
        // const address = response.data.suggestions[0].address;
        // const id = response.data.suggestions[0].locationId;
      });
    }
  }

  render() {

    const { location, isValid, fieldValue, geoCoordinates } = this.state;
    const { typology } = this.props;

    const MapConfirmation = () => {
      if (geoCoordinates !== null) {
        return (
          <>
            <Divider
              borderColor="gray.200"
              my={5}
            />
            <Text><FormErrorMessage id="form.step4.where.is.it.here" /></Text>
            <img width="295" alt="Localisation de votre annonce" height="150" src={`https://maps.googleapis.com/maps/api/staticmap?center=${geoCoordinates.Latitude},${geoCoordinates.Longitude}&zoom=7&autoscale=1&size=295x150&maptype=roadmap&key=AIzaSyC7O1XSp3BY1qkSUWKhR0hl4mOHcCIxi_U&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0xff0000%7Clabel:1%7C${geoCoordinates.Latitude},${geoCoordinates.Longitude}`} />
          </>
        )
      } else {
        return (null)
      }
    }



    const reset = (e) => {
      // console.log('reset')
      this.setState({
        'fieldValue': undefined,
        'location': null,
        'isValid': false
      })
    }

    const handleValid = (e) => {
      this.setState({ 'isValid': true })
      this.props.handleChange("location", this.state.location)
      this.props.handleChange("geoCoordinates", this.state.geoCoordinates)

    }

    const handleChange = (value) => {
      if (value.length > 0) {
        this.setState({ fieldValue: value },
          () => {
            this.getGeoCode();
          })
      }
      else {
        this.setState({
          location: null,
          fieldValue: value
        })
      }
    }

    return (
      <FormControl>
        <FormLabel htmlFor="Location">
          <FormattedMessage id="form.step4.where.is" />{' '}
          {(typology === 'isBoat') ? <FormattedMessage id="form.step4.the.boat" /> : null}
          {(typology === 'isSail') ? <FormattedMessage id="form.step4.the.sails" /> : null}
          {(typology === 'isPart') ? <FormattedMessage id="form.step4.the.parts" /> : null}
          {(typology === 'isTrolley') ? <FormattedMessage id="form.step4.the.trolley" /> : null}
          &nbsp;?
        </FormLabel>
        <Input
          placeholder={this.props.intl.formatMessage({ id: 'form.step.4.city.department.country' })}
          // "Département, ville"
          value={fieldValue}
          onBlur={(e) => handleChange(e.target.value)}
          onChange={(e) => handleChange(e.target.value)}
          disabled={isValid}
          size="md"
        />
        <FormHelperText id="email-helper-text">
          {(!isValid) ?
            <FormattedMessage id="form.step4.notice" />
            // "Tapez la ville, et éventuellement le département ou le pays si c'est hors de France."
            : null}
          {(isValid) ?
            <Button
              onClick={reset}
              mt={2}
              size="xs"
              variant="outline">
              <FormattedMessage id="main.update" />
            </Button>
            : null}
        </FormHelperText>

        {(!isValid && location !== null) ?
          <MapConfirmation /> : null}

        {(!isValid && location !== null) ?
          <Button
            mt={4}
            colorScheme="teal"
            variant="outline"
            type="submit"
            onClick={handleValid}
          >
            <FormattedMessage id="form.step4.confirm.this.location" />
          </Button> : null}
      </FormControl>
    )
  }
}
export default injectIntl(Step4Location)